export const ShowSelfRagistration: { [key: string]: boolean } = {
  betlaal: true,
  hypexexch: true,
  vivaexch: true,
  ntexch: true,
  speed247exch: true,
  stbexch: true,
  vardaanexch: true,
  allplay99: true,
  classicbook247: true,
  dhanexchange: true,
  betinr: true,
  mazaa365: true,
  faircric: true,
  betex247: true,
  '365fairplay': true,
  shaexch: true,
  jinnyexch: true,
  sultan: true,
  '999betpro': true,
  maruti247: true,
  khelbazi: true,
  richestplay999: true,
  trishul999: true,
  vipexch3: true,
  reya5: true,
  cricanna: true,
  mykingexch: true,
  bb247: true,
  theboys7: true,
  owlexch77: true,
  adani99: true,
  mudra999: true,
  kuber333: true,
  bb777: true,
  shreejiexch: true,
  a7exch: true,
};

export const ShowPaymentMethods: { [key: string]: boolean } = {
  betex247: true,
  mazaa365: true,
  faircric: true,
  '365fairplay': true,
};

export const ShowWhatsopIcon: { [key: string]: boolean } = {
  betlaal: true,
  hypexexch: true,
  vivaexch: true,
  ntexch: true,
  speed247exch: true,
  stbexch: true,
  vardaanexch: true,
  budthabookiee: true,
  allplay99: true,
  classicbook247: true,
  cricbuzzsport: true,
  dhanexchange: true,
  betinr: true,
  mazaa365: true,
  faircric: true,
  betex247: true,
  sikander247: true,
  '365fairplay': true,
  shaexch: true,
  jinnyexch: true,
  sultan: true,
  '999betpro': true,
  maruti247: true,
  khelbazi: true,
  richestplay999: true,
  trishul999: true,
  vipexch3: true,
  reya5: true,
  cricanna: true,
  mykingexch: true,
  bb247: true,
  theboys7: true,
  owlexch77: true,
  adani99: true,
  mudra999: true,
  kuber333: true,
  bb777: true,
  shreejiexch: true,
  a7exch: true,
};

export const ShowWelcomePage: { [key: string]: boolean } = {
  //faircric
  'faircric.co': true,
  'faircric.in': true,
  'faircric.live': true,
  'faircric.world': true,
  'faircric.co.in': true,
  'faircric.online': true,
  //mazaa365
  'mazaa365.in': true,
  'mazaa365.co': true,
  'mazaa365.live': true,
  'mazaa365.world': true,
  'mazaa365.co.in': true,
  'mazaa365.online': true,
  //betex247
  'betex247.in': true,
  'betex247.co': true,
  'betex247.live': true,
  'betex247.world': true,
  'betex247.co.in': true,
  'betex247.online': true,
};

export const WHDetails: {
  [key: string]: {
    whatsapp: string;
    bank: string;
    accType: string;
    accName: string;
    accNum: string;
    ifsc: string;
    gpayNum: string;
    gpayUpi: string;
    phonePayNum: string;
    phonePayUpi: string;
    paytmNum: string;
  };
} = {
  dingo777: {
    whatsapp: '+919328893488',
    bank: 'ICIC Bank',
    accType: 'CURRENT ACCOUNT',
    accName: 'HARIOM JEWELLERS',
    accNum: '030805501063',
    ifsc: 'ICIC0000308',
    gpayNum: '+9195860 03386',
    gpayUpi: 'jalaramjnd1@okaxis',
    phonePayNum: '+919586003386',
    phonePayUpi: 'ronakatm@ybl',
    paytmNum: null,
  },
  wazirexch: {
    whatsapp: '+91**********',
    bank: '***** Bank',
    accType: 'CURRENT ACCOUNT',
    accName: '**** *****',
    accNum: '********',
    ifsc: '*******',
    gpayNum: '+91******',
    gpayUpi: '******@****',
    phonePayNum: '+91********',
    phonePayUpi: '******@***',
    paytmNum: null,
  },
};
